<div class="sidebar" data-color="blue">
  <div class="logo">
    <a class="simple-text logo-mini">
      <div class="logo-img">
        <img src="../../../assets/img/serhafen.blanco.dos.svg" />
      </div>
    </a>
    <a class="simple-text logo-normal"> SERHAFEN </a>
    <div class="navbar-minimize">
      <button id="minimizeSidebar" class="btn btn-simple btn-icon btn-neutral btn-round" (click)="minimizeSidebar()">
        <i class="now-ui-icons text_align-center visible-on-sidebar-regular"></i>
        <i class="now-ui-icons design_bullet-list-67 visible-on-sidebar-mini"></i>
      </button>
    </div>
  </div>
  <div class="sidebar-wrapper">
    <div class="user">
      <div class="photo"><img src="../assets/img/cara.png" /></div>
      <div class="info">
        <a>
          <span> {{ this.user?.name }} {{ this.user?.lastName }} </span>
        </a>
      </div>
    </div>
    <ul class="nav">
      <li routerLinkActive="active" *ngFor="let menuitem of menuItems">
        <!--If is a single link-->
        <a [routerLink]="[menuitem?.path]" *ngIf="menuitem?.type === 'link'">
          <i class="now-ui-icons {{ menuitem?.icontype }}"></i>
          <p>{{ menuitem?.title }}</p>
        </a>
        <!--If it have a submenu-->
        <a
          data-toggle="collapse"
          href="#{{ menuitem?.collapse }}"
          *ngIf="menuitem?.type === 'sub'"
          (click)="myFunc($event, menuitem); menuitem?.isCollapsing === undefined && (menuitem.isCollapsed = !menuitem?.isCollapsed)"
          [attr.aria-expanded]="!menuitem?.isCollapsed"
        >
          <i class="now-ui-icons {{ menuitem?.icontype }}"></i>
          <p>{{ menuitem?.title }}<b class="caret"></b></p>
        </a>

        <!--Display the submenu items-->
        <div
          id="{{ menuitem?.collapse }}"
          class="collapse"
          *ngIf="menuitem?.type === 'sub'"
        >
        <!-- [ngbCollapse]="menuitem?.isCollapsed" for open menus -->
          <ul class="nav">
            <li routerLinkActive="active" *ngFor="let childitem of menuitem?.children">
              <a [routerLink]="[menuitem?.path, childitem?.path]">
                <span class="sidebar-mini-icon">{{ childitem?.ab }}</span>
                <span class="sidebar-normal">{{ childitem?.title }}</span>
              </a>
            </li>
          </ul>
        </div>
      </li>
    </ul>
    <div
      class="user"
      style="cursor: pointer; position: fixed; bottom: 0; left: 0; margin-top: 20px; max-width: 80px"
      (click)="logout()"
    >
      <div class="photo"><img src="../assets/img/logout.png" /></div>
      <div class="info">
        <a>
          <span> Cerrar sesión </span>
        </a>
      </div>
    </div>
  </div>
</div>
