<div class="fixed-plugin">
  <div ngbDropdown>
    <a href="javascript:void(0)" ngbDropdownToggle id="dropdownConfig">
      <i class="fa fa-cog fa-2x"> </i>
    </a>
    <ul x-placement="bottom-start"
      style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-231px, 38px, 0px);"
      ngbDropdownMenu aria-labelledby="dropdownConfig">
      <li class="header-title"> Sidebar Background</li>
      <li class="adjustments-line">
        <a href="javascript:void(0)" class="switch-trigger background-color">
          <div class="badge-colors text-center">
            <span class="badge filter badge-yellow" [ngClass]="{'active':sidebarColor==='yellow'}"
              (click)="changeSidebarColor('yellow')"></span>
            <span class="badge filter badge-blue" [ngClass]="{'active':sidebarColor==='blue'}"
              (click)="changeSidebarColor('blue')"></span>
            <span class="badge filter badge-green" [ngClass]="{'active':sidebarColor==='green'}"
              (click)="changeSidebarColor('green')"></span>
            <span class="badge filter badge-orange active" [ngClass]="{'active':sidebarColor==='orange'}"
              (click)="changeSidebarColor('orange')"></span>
            <span class="badge filter badge-red" [ngClass]="{'active':sidebarColor==='red'}"
              (click)="changeSidebarColor('red')"></span>
          </div>
          <div class="clearfix"></div>
        </a>
      </li>


      <li class="header-title">
        Sidebar Mini
      </li>
      <li class="adjustments-line">

        <div class="togglebutton switch-sidebar-mini">
          <span class="label-switch">OFF</span>
          <bSwitch [switch-on-color]="'primary'" [switch-off-text]="' '" [switch-on-text]="' '" [(ngModel)]="state"
            (onChangeState)="onChange($event)" (click)="onChange($event)"></bSwitch>
          <span class="label-switch label-right">ON</span>
        </div>
      </li>
      <br>
      <br>
      <span></span>
    </ul>
  </div>
</div>