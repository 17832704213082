import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

var misc: any = {
  sidebar_mini_active: true,
};

export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  collapse?: string;
  isCollapsed?: boolean;
  isCollapsing?: any;
  children?: ChildrenItems[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  ab: string;
  type?: string;
}

//Menu Items
export const ROUTES: RouteInfo[] = [
  // {
  //   path: '/dashboard',
  //   title: 'Dashboard',
  //   type: 'link',
  //   icontype: 'design_app',
  // },
  {
    path: '/awb',
    title: 'Gestión MAWB',
    type: 'sub',
    icontype: 'files_single-copy-04',
    collapse: 'awb',
    isCollapsed: false,
    children: [
      { path: 'load-awb', title: 'Cargar MAWB', ab: 'CM' },
      { path: 'list-awb', title: 'Listado MAWB', ab: 'LM' },
      { path: 'traceability', title: 'Trazabilidad MAWB', ab: 'TM' },
    ],
  },
  {
    path: '/truck/travel',
    title: 'Gestión viajes',
    type: 'sub',
    icontype: 'files_single-copy-04',
    collapse: 'awb',
    isCollapsed: false,
    children: [
      { path: 'planification', title: 'Planificación viaje', ab: 'PV' },
      { path: 'available', title: 'Viaje camión', ab: 'VC' },
      { path: 'report', title: 'Reporte MAWB', ab: 'RM' },
    ],
  },

  {
    path: '/last/mile',
    title: 'Gestión HAWB',
    type: 'sub',
    icontype: 'files_single-copy-04',
    collapse: 'awb',
    isCollapsed: false,
    children: [
      { path: 'delivery', title: 'MAWB', ab: 'EUM' },
      { path: 'history', title: 'HISTORIAL DE CAMBIOS', ab: 'HDC' },
      { path: 'enlace', title: 'INTERCOMUNICADOR WS CAINIAO', ab: 'IWC' },
      { path: 'messege', title: 'MENSAJES RECIBIDOS CAINIAO', ab: 'MRC' },
      { path: 'codigos', title: 'CODIGOS DE ESTADO', ab: 'CE' },
      { path: 'hawb', title: 'HAWB', ab: 'HA' },
    ],
  },
  {
    path: '/maintainers',
    title: 'Mantenedores',
    type: 'sub',
    icontype: 'files_single-copy-04',
    collapse: 'awb',
    isCollapsed: false,
    children: [
      { path: 'tariff-items', title: 'Partidas arancelarias', ab: 'PA' },
      { path: 'transport', title: 'Transporte', ab: 'TR' },
      { path: 'scan', title: 'Escaneo Diario', ab: 'ED' },
      { path: 'districts', title: 'Comunas', ab: 'CO' },
    ],
  },
  {
    path: '/ddu',
    title: 'DDU',
    type: 'sub',
    icontype: 'files_single-copy-04',
    collapse: 'DDU',
    isCollapsed: false,
    children: [{ path: 'reportDDU', title: 'Reporte DDU', ab: 'RDDU' }],
  },
  {
    path: '/reports',
    title: 'Reportes',
    type: 'sub',
    icontype: 'files_single-copy-04',
    collapse: 'RE',
    isCollapsed: false,
    children: [
      { path: 'awb-detail', title: 'Hijas', ab: 'HI' }
    ]
  },
  {
    path: '/truck/travel/external',
    title: 'Gestión carga',
    type: 'sub',
    icontype: 'files_single-copy-04',
    collapse: 'truck-travel-external',
    isCollapsed: false,
    children: [
      { path: 'register-courier', title: 'Registrar Courier', ab: 'RC' },
    ],
  },
  // {
  //   path: "/pages",
  //   title: "Pages",
  //   type: "sub",
  //   icontype: "design_image",
  //   collapse: "pages",
  //   isCollapsed: true,
  //   children: [
  //     { path: "pricing", title: "Pricing Page", ab: "PP" },
  //     { path: "timeline", title: "Timeline Page", ab: "TP" },
  //     { path: "login", title: "Iniciar Sesión", ab: "LP" },
  //     { path: "register", title: "Register Page", ab: "RP" },
  //     { path: "lock", title: "Lock Screen Page", ab: "LSP" },
  //     { path: "user", title: "User Page", ab: "UP" },
  //   ],
  // },
  // {
  //   path: "/components",
  //   title: "Components",
  //   type: "sub",
  //   icontype: "education_atom",
  //   collapse: "components",
  //   isCollapsed: true,
  //   children: [
  //     { path: "buttons", title: "Buttons", ab: "B" },
  //     { path: "grid", title: "Grid System", ab: "GS" },
  //     { path: "panels", title: "Panels", ab: "P" },
  //     { path: "sweet-alert", title: "Sweet Alert", ab: "SA" },
  //     { path: "notifications", title: "Notifications", ab: "N" },
  //     { path: "icons", title: "Icons", ab: "I" },
  //     { path: "typography", title: "Typography", ab: "T" },
  //   ],
  // },
  // {
  //   path: "/forms",
  //   title: "Forms",
  //   type: "sub",
  //   icontype: "files_single-copy-04",
  //   collapse: "forms",
  //   isCollapsed: true,
  //   children: [
  //     { path: "regular", title: "Regular Forms", ab: "RF" },
  //     { path: "extended", title: "Extended Forms", ab: "EF" },
  //     { path: "validation", title: "Validation Forms", ab: "VF" },
  //     { path: "wizard", title: "Wizard", ab: "W" },
  //   ],
  // },
  // {
  //   path: "/tables",
  //   title: "Tables",
  //   type: "sub",
  //   icontype: "design_bullet-list-67",
  //   collapse: "tables",
  //   isCollapsed: true,
  //   children: [
  //     { path: "regular", title: "Regular Tables", ab: "RT" },
  //     { path: "extended", title: "Extended Tables", ab: "ET" },
  //     { path: "ngx-datatable", title: "Ngx Datatable", ab: "ND" },
  //   ],
  // },
  // {
  //   path: "/maps",
  //   title: "Maps",
  //   type: "sub",
  //   icontype: "location_pin",
  //   collapse: "maps",
  //   isCollapsed: true,
  //   children: [
  //     { path: "google", title: "Google Maps", ab: "GM" },
  //     { path: "fullscreen", title: "Full Screen Map", ab: "FSM" },
  //     { path: "vector", title: "Vector Map", ab: "VM" },
  //   ],
  // },
  // {
  //   path: "/widgets",
  //   title: "Widgets",
  //   type: "link",
  //   icontype: "objects_diamond",
  // },
  // {
  //   path: "/charts",
  //   title: "Charts",
  //   type: "link",
  //   icontype: "business_chart-pie-36",
  // },
  // {
  //   path: "/calendar",
  //   title: "Calendar",
  //   type: "link",
  //   icontype: "media-1_album",
  // },
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent implements OnInit {
  public menuItems: any[];
  public isCollapsed = false;
  public user: any;

  // credentials
  readBroker = false;
  createBroker = false;
  updateBroker = false;

  readFacility = false;
  createFacility = false;
  updateFacility = false;

  readAlmacen = false;
  createAlmacen = false;
  updateAlmacen = false;

  readAerolinea = false;
  createAerolinea = false;
  updateAerolinea = false;

  readCourier = false;
  createCourier = false;
  updateCourier = false;
  // credentials

  constructor(private toastr: ToastrService, private router: Router) { }

  ngOnInit() {
    this.menuItems = ROUTES.filter((menuItem) => menuItem);
    this.minimizeSidebarAuto();
    // credentials

    setTimeout(() => {
      try {
        this.user = localStorage.getItem('user');
        this.user = JSON.parse(this.user);
        delete this.user.token;

        for (let item of this.user.profile) {
          if (item.profile === 'Broker' && item.action === 'R') {
            this.readBroker = true;
          }

          if (item.profile === 'Broker' && item.action === 'C') {
            this.createBroker = true;
          }

          if (item.profile === 'Broker' && item.action === 'U') {
            this.updateBroker = true;
          }

          if (item.profile === 'Facility' && item.action === 'R') {
            this.readFacility = true;
          }

          if (item.profile === 'Facility' && item.action === 'C') {
            this.createFacility = true;
          }

          if (item.profile === 'Facility' && item.action === 'U') {
            this.updateFacility = true;
          }

          if (item.profile === 'Almacen' && item.action === 'R') {
            this.readAlmacen = true;
          }

          if (item.profile === 'Almacen' && item.action === 'C') {
            this.createAlmacen = true;
          }

          if (item.profile === 'Almacen' && item.action === 'U') {
            this.updateAlmacen = true;
          }

          if (item.profile === 'Aerolinea' && item.action === 'R') {
            this.readAerolinea = true;
          }

          if (item.profile === 'Aerolinea' && item.action === 'C') {
            this.createAerolinea = true;
          }

          if (item.profile === 'Aerolinea' && item.action === 'U') {
            this.updateAerolinea = true;
          }

          if (item.profile === 'Courier' && item.action === 'U') {
            this.readCourier = true;
          }

          if (item.profile === 'Courier' && item.action === 'U') {
            this.createCourier = true;
          }

          if (item.profile === 'Courier' && item.action === 'U') {
            this.updateCourier = true;
          }
        }

        if (!this.readBroker && !this.readCourier) {
          delete this.menuItems[0].children[1];
        }

        if (!this.createBroker && !this.createCourier) {
          delete this.menuItems[0].children[0];
        }

        if (!this.readFacility) {
          delete this.menuItems[1].children[1];
        } else {
          delete this.menuItems[0];
        }

        if (!this.readBroker && !this.readFacility) {
          if (this.readAlmacen) {
            delete this.menuItems[0];
            delete this.menuItems[1].children[2];
            delete this.menuItems[2];
          }

          if (this.readCourier) {
            delete this.menuItems[0].children[2];
            delete this.menuItems[0].children[1];
            delete this.menuItems[1].children[2];
            delete this.menuItems[2];
            delete this.menuItems[3];
          }
        }

        if (!this.readBroker && !this.readFacility && !this.readAlmacen) {
          if (this.readAerolinea) {
            delete this.menuItems[0];
            delete this.menuItems[1].children[2];
          }
        }
      } catch {
        console.error('error');
      }

      if (localStorage.getItem('reload')) {
        localStorage.removeItem('reload');
        location.reload();
      }
    }, 300);
    // credentials
  }

  sleep(milliseconds) {
    var start = new Date().getTime();
    for (var i = 0; i < 1e7; i++) {
      if (new Date().getTime() - start > milliseconds) {
        break;
      }
    }
  }
  myFunc(event, menuitem) {
    event.preventDefault();
    event.stopPropagation();
    this.sleep(10);
    if (menuitem.isCollapsing === undefined) {
      menuitem.isCollapsing = true;

      // menuitem.isCollapsed = !menuitem.isCollapsed;

      var element = event.target;
      while (
        element.getAttribute('data-toggle') != 'collapse' &&
        element != document.getElementsByTagName('html')[0]
      ) {
        element = element.parentNode;
      }
      element = element.parentNode.children[1];

      if (element.classList.contains('collapse') && !element.classList.contains('show')) {
        element.classList = 'before-collapsing';
        var style = element.scrollHeight;

        element.classList = 'collapsing';
        setTimeout(function () {
          element.setAttribute('style', 'height:' + style + 'px');
        }, 1);
        setTimeout(function () {
          element.classList = 'collapse show';
          element.removeAttribute('style');
          menuitem.isCollapsing = undefined;
        }, 350);
      } else {
        var style = element.scrollHeight;
        setTimeout(function () {
          element.setAttribute('style', 'height:' + (style + 20) + 'px');
        }, 3);
        setTimeout(function () {
          element.classList = 'collapsing';
        }, 3);
        setTimeout(function () {
          element.removeAttribute('style');
        }, 20);
        setTimeout(function () {
          element.classList = 'collapse';
          menuitem.isCollapsing = undefined;
        }, 400);
      }
    }
  }

  minimizeSidebarAuto() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('sidebar-mini');
    misc.sidebar_mini_active = true;
  }

  minimizeSidebar() {
    const body = document.getElementsByTagName('body')[0];
    if (body.classList.contains('sidebar-mini')) {
      misc.sidebar_mini_active = true;
    } else {
      misc.sidebar_mini_active = false;
    }
    if (misc.sidebar_mini_active === true) {
      body.classList.remove('sidebar-mini');
      misc.sidebar_mini_active = false;
    } else {
      body.classList.add('sidebar-mini');
      misc.sidebar_mini_active = true;
    }

    // we simulate the window Resize so the charts will get updated in realtime.
    const simulateWindowResize = setInterval(function () {
      window.dispatchEvent(new Event('resize'));
    }, 180);

    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(function () {
      clearInterval(simulateWindowResize);
    }, 1000);
  }

  showSidebarMessage(message) {
    this.toastr.show('<span class="now-ui-icons ui-1_bell-53"></span>', message, {
      timeOut: 4000,
      closeButton: true,
      enableHtml: true,
      toastClass: 'alert alert-danger alert-with-icon',
      positionClass: 'toast-top-right',
    });
  }

  logout() {
    localStorage.removeItem('user');
    this.router.navigateByUrl('/auth/login');
  }
}
